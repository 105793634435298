import React from "react";
import {Image, Card, Button, CardGroup, Jumbotron, Container} from "react-bootstrap";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Link } from "gatsby";
import MV from "../images/bn.jpg"
import THM01 from "../images/thumb01.jpg"
import THM02 from "../images/thumb02.jpg"
import THM03 from "../images/thumb03.jpg"
import FV from "../images/bn02.jpg"

console.log(MV); // MV image
console.log(THM01); // MV image
console.log(THM02); // MV image
console.log(THM03); // MV image
console.log(FV); // MV image

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Image src={MV} fluid />
    <Container>
    <CardGroup>
  <Card>
    <Card.Img variant="top" src={THM01} />
    <Card.Body>
      <Card.Title>Import & Export</Card.Title>
      <Card.Text>
      At the crossroads of cultures, a new culture is always born. We connect as a hub spot for them.
      </Card.Text>
    </Card.Body>
  </Card>
  <Card>
    <Card.Img variant="top" src={THM02} />
    <Card.Body>
      <Card.Title>Web Media, E-Commerce</Card.Title>
      <Card.Text>
      Knowing and being exposed to other cultures, we facilitate new encounters online.{' '}
      </Card.Text>
    </Card.Body>
  </Card>
  <Card>
    <Card.Img variant="top" src={THM03} />
    <Card.Body>
      <Card.Title>Web Design, Produce</Card.Title>
      <Card.Text>
      We support site creation using existing CMS systems and EC platforms. Also please contact us about event sites and corporate sites.
      </Card.Text>
    </Card.Body>
  </Card>
</CardGroup>
  </Container>
  <br></br><br></br>
  <Container>
<Card className="bg-dark text-white">
  <Card.Img src={FV} alt="Card image" />
</Card>
</Container>
<Container>
<Card className="text-center">
  <Card.Body>
    <Card.Title>Here's to new encounters.</Card.Title>
    <Card.Text>
    Please contact us in Japanese, Vietnamese, or English.<br></br>
    日本語ネイティブのスタッフが対応いたします、お気軽にお問い合わせください。
    </Card.Text>
    <Button href="/contact" variant="contact" size="lg">CONTACT</Button>
  </Card.Body>
</Card>
</Container>
  </Layout>
);  

export default IndexPage;
